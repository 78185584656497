import React from "react";

const GameOverlay = ({ onRestart, board, bgAudio, winSound, gameOverSound }) => {
  const renderShareBtn = (hasWon) => {
    const winningTweet = "Just%20conquered%20the%20cosmos%20in%20%23StarMerge%E2%9C%A8!%20Epic%20NFT%20merges%20by%20%40trekolabs%20on%20%40stargazezone's%20blockchain%20are%20my%20new%20obsession.%20%F0%9F%8C%8C%F0%9F%AA%90%20%23NFTGaming%20%23Victory"
    const gameOverTweet = `Ventured%20into%20%23StarMerge%E2%9C%A8%20universe%2C%20merging%20captivating%20%40trekolabs%20NFTs%20on%20%40stargazezone%20blockchain.%20Game%20over%20for%20now%2C%20with%20a%20score%20of%20${board.score}%20points%2C%20but%20the%20stargazing%20continues%21%20%F0%9F%8C%A0%F0%9F%8E%AE%20%23NFTGaming%20%23GameOver`
    return (
      <a
        className="twitter-share-button"
        target="_blank"
        href={`https://twitter.com/intent/tweet?text=${hasWon ? winningTweet : gameOverTweet}&url=https%3A%2F%2Ftrekolabs.com`}
        data-size="large" rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        <svg viewBox="0 0 24 24" aria-hidden="true"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
        <span>SHARE</span>
      </a>
    );
  };

  if (board.hasWon()) {
    if (!winSound.playing()) {
      bgAudio.stop();
      winSound.play();
    }
    return <div className="win" onClick={onRestart}>
      {renderShareBtn(true)}
    </div>;
  } else if (board.hasLost()) {
    if (!gameOverSound.playing()) {
      bgAudio.stop();
      gameOverSound.play();
    }
    return (
      <div className="gameOver" onClick={onRestart}>
        {renderShareBtn(false)}
      </div>
    );
  }

  return null;
};

export default GameOverlay;
