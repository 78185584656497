import React, { useEffect, useState } from "react";
import { Howl } from 'howler';
import Tile from "./Tile";
import Cell from "./Cell";
import { Board } from "../helper";
import { useEvent, usePreloadImages } from "../hooks";
import GameOverlay from "./GameOverlay";
import Logo from "../assets/img/logo.png";

import img2 from '../assets/img/2.gif';
import img4 from '../assets/img/4.gif';
import img8 from '../assets/img/8.gif';
import img16 from '../assets/img/16.gif';
import img32 from '../assets/img/32.gif';
import img64 from '../assets/img/64.gif';
import img128 from '../assets/img/128.gif';
import img256 from '../assets/img/256.gif';
import img512 from '../assets/img/512.gif';
import img1024 from '../assets/img/1024.gif';
import img2048 from '../assets/img/2048.gif';
import img4096 from '../assets/img/4096.gif';
import imgGameOver from '../assets/img/game-over.gif';
import imgWin from '../assets/img/win.gif';

import bgAudio from '../assets/sound/bg.m4a';
import winAudio from '../assets/sound/win.m4a';
import gameOverAudio from '../assets/sound/gameover.m4a';

const audio = new Howl({ src: [bgAudio], autoplay: true, volume: 0.6, loop: true });

const winSound = new Howl({ src: [winAudio] });
const gameOverSound = new Howl({ src: [gameOverAudio] });

const preload = [ img2, img4, img8, img16, img32, img64, img128, img256, img512, img1024, img2048, img4096, imgGameOver, imgWin ];

const BoardView = () => {
  const [board, setBoard] = useState(new Board());
  const [lastScore, setLastScore] = useState(0);
  const [showScoreAdd, setShowScoreAdd] = useState(false);
  const [diffScore, setDiffScore] = useState(0);
  const [startTouchPos, setStartTouchPos] = useState({ x: 0, y: 0 });
  const [recorded, setRecorded] = useState(false);

  usePreloadImages(preload);

  const moveTo = (direction) => {
    // 0 -> left, 1 -> up, 2 -> right, 3 -> down
    setShowScoreAdd(false);
    let boardClone = Object.assign(
      Object.create(Object.getPrototypeOf(board)),
      board
    );
    let newBoard = boardClone.move(direction);
    setBoard(newBoard);
  }

  useEffect(() => {
    const handleTouchStart = (event) => {
      const touch = event.touches[0];
      setStartTouchPos({ x: touch.clientX, y: touch.clientY });
    };

    const handleTouchMove = () => {};

    const handleTouchEnd = (event) => {
      const touch = event.changedTouches[0];
      const endTouchPos = { x: touch.clientX, y: touch.clientY };

      const dx = endTouchPos.x - startTouchPos.x;
      const dy = endTouchPos.y - startTouchPos.y;

      const absDx = Math.abs(dx);
      const absDy = Math.abs(dy);

      const swipeThreshold = 50;
      if (absDx > swipeThreshold || absDy > swipeThreshold) {
        if (absDx > absDy) {
          if (dx > 0) {
            moveTo(2);
          } else {
            moveTo(0);
          }
        } else {
          if (dy > 0) {
            moveTo(3);
          } else {
            moveTo(1);
          }
        }
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    window.addEventListener('dragstart', event => {
      event.preventDefault();
    });

    window.addEventListener('drop', event => {
      event.preventDefault();
    });

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTouchPos]);

  const handleKeyDown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!recorded && (board.hasWon() || board.hasLost())) {
      const urlParams = new URLSearchParams(window.location.search);
      const account = urlParams.get('viewer');
      if (account) {
        setRecorded(true);
        fetch(`https://v1.trekolabs.com/api/game/starmerge?arg1=${account}&arg2=${board.startTime}&arg3=${board.endTime}&arg4=${board.score}&arg5=${board.hasWon() ? 'true' : 'false'}`, { credentials: 'omit', method: "GET", redirect: 'follow' })
      }

      if (board.hasWon()) {
        return;
      }
    }

    if (event.keyCode >= 37 && event.keyCode <= 40) {
      const direction = event.keyCode - 37;
      moveTo(direction)
    }
  };

  useEvent("keydown", handleKeyDown);

  const cells = board.cells.map((row, rowIndex) => {
    return (
      <div key={rowIndex}>
        {row.map((col, colIndex) => {
          return <Cell key={rowIndex * board.size + colIndex} />;
        })}
      </div>
    );
  });

  const tiles = board.tiles
    .filter((tile) => tile.value !== 0)
    .map((tile, index) => {
      return <Tile tile={tile} key={`${board.score}-${index}`} />;
    });

  const resetGame = () => {
    if (winSound.playing()) {
      winSound.stop();
    } else if (gameOverSound.playing()) {
      gameOverSound.stop();
    }
    if (!audio.playing()) audio.play();
    setDiffScore(0);
    setLastScore(0);
    setRecorded(false);
    setBoard(new Board());
  };

  useEffect(() => {
    if (board.score > 0) {
      setDiffScore(board.score - lastScore);
      setLastScore(board.score);
      setShowScoreAdd(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board.score]);

  return (
    <>
      <a href="https://www.trekolabs.com" target="_blank" rel="noreferrer">
        <img
            className="logo-mobile"
            src={Logo}
            alt="Logo"
            style={{
              height: 40,
            }}
        />
      </a>
      <div className="board">
        {cells}
        {tiles}
        <GameOverlay onRestart={resetGame} board={board} bgAudio={audio} winSound={winSound} gameOverSound={gameOverSound} />
      </div>
      <div className="details-box">
      <div className="score-box">
        <div className="score-header">SCORE</div>
        <div className="score-value">{board.score}</div>
        {showScoreAdd ? <div className="score-addition">{`+${diffScore}`}</div> : null}
      </div>
      <a href="https://www.trekolabs.com" target="_blank" rel="noreferrer">
        <img
          className="logo"
          src={Logo}
          alt="Logo"
          style={{
            height: 40,
          }}
        />
      </a>
      <div className="resetButton" onClick={resetGame}>
        New Game
      </div>
    </div>
  </>
  );
};

export default BoardView;
